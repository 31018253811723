import React from "react";
import classnames from "classnames";
import Holder from '../Holder';
import { lp1block2 } from "./content/lp1block2";
import './block2.css';

const block2Content = {
  lp1block2,
};

export const Block2 = ({ block2ContentKey, className }) => {
  // const isMobile = useMobile();
  return (
    <div className={classnames('mathonlp__block2', className )}>
      <Holder type={1} className={'mathonlp__block2-holder'}>
        <h2 className="mathonlp__block2-title">
          { block2Content[block2ContentKey].title }
        </h2>
        <div className="mathonlp__block2-list">
          {block2Content[block2ContentKey].items.map((item, key) => (
            <div
              className="mathonlp__block2-item"
              key={`mathon__block-2-item-${key}`}
            >
              <img
                className="mathonlp__block2-img"
                src={require(`./img/${item.img}.png`)}
                srcSet={require(`./img/${item.img}@2x.png`) + ' 2x'}
                height="40"
                width="40"
                alt=""/>
              <h3 className="mathonlp__block2-item-title">{item.title}</h3>
              <div className="mathonlp__block2-item-text">{item.text}</div>
            </div>
          ))}
        </div>
        <img className="mathonlp__block2-doodle-img"
             src={require('./img/dooddle.svg')}
             width="159" height="114"
             alt=""/>
      </Holder>
    </div>
  );
};

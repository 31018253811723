import React, { Component } from 'react';
import classnames from 'classnames';

import './index.css';

export default class Modal extends Component {
  render() {
    const { className, children, isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <div className={classnames('modal', className)}>
        <div className="modal__body">
          {children}
        </div>
      </div>
    )
  }
}

import css from 'dom-css';
import
  //tracker,
  { storageGetItem }
  from '../lrtracker';

export const isClient = typeof window !== 'undefined';

export const isMobile = isClient
  ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  : false;

export const setCookie = (cname, cvalue, exdays) => {
  let date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);

  let expires = 'expires=' + date.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = '';

  try {
    decodedCookie = decodeURIComponent(document.cookie);
  } catch (error) {
    console.error("decodedCookie:",error);
  }

  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkCookie = (cname) => {
  return getCookie(cname) !== '';
};

export const deleteCookie = (cname) => {
  document.cookie = cname + '=; Max-Age=-99999999;';
};

/**
 * clearPhone
 * @param {String} number
 */
export const clearPhone = (number) => {
  return number ? number.replace(/-| |\(|\)/gi, '') : '';
};

export const parseQuery = (search) => {
  const query = {};
  try {
    const pairs = (search[0] === '?' ? search.substr(1) : search).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
  } catch (error) {
    console.error("parseQuery:",error);
  }
  return query;
};

export const getGtagClientId = () => {
  function getCookie(name) {
    var matches =
      isClient &&
      document.cookie.match(
        new RegExp(
          '(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'
        )
      );

    let gtagClientId;
    try {
      gtagClientId = decodeURIComponent(matches[1]);
    } catch (error) {
      console.error("getGtagClientId:",error);
    }

    return gtagClientId;
  }

  const _ga = getCookie('_ga');

  if (_ga) {
    if (/^GA\d\./.test(_ga)) {
      return _ga.replace(/^GA\d\.\d\./, '');
    }

    if (/^amp-/.test(_ga)) {
      return _ga.replace(/^amp-/, '');
    }

    return _ga;
  }

  return false;
};

/**
 * saveQueryToStorage
 */
export const saveQueryToStorage = (isSavePathForTransition) => {
  const { pathname, search, host } = window.location;
  const savedPath = localStorage.getItem('savedPath');

  if (isSavePathForTransition && !savedPath) {
    localStorage.setItem('savedPath', pathname);
  } else {
    localStorage.setItem(
      'query',
      JSON.stringify({
        path: host + pathname,
        search: search.substring(1).replace(/\+/g, '%2B'),
      })
    );
  }
};

export const getLocalStorageQuery = () => {
  const query = JSON.parse(localStorage.getItem('query')) || {
    path: '',
    search: '',
  };

  const clientId = getGtagClientId();
  const tcid = storageGetItem('tcid');

  const gClientQuery = clientId ? `&ga_client_id=${clientId}` : '';
  const tcidQuery = tcid ? `&tcid=${tcid}` : '';

  const gaSessionId = getCookie('_ga_session_id') || '';
  const gaSessionIdQuery = gaSessionId ? `&ga_session_id=${gaSessionId}` : '';

  let search = `${query.search}${gClientQuery}${gaSessionIdQuery}${tcidQuery}`;
  if (search.startsWith('&')) {
    search = search.substring(1);
  }

  return {
    path: query.path,
    search: search,
  };
};

export const getScrollbarWidth = () => {
  let scrollbarWidth = false;

  if (scrollbarWidth !== false) {
    return scrollbarWidth;
  }

  if (typeof document !== 'undefined') {
    const div = document.createElement('div');
    css(div, {
      width: 100,
      height: 100,
      position: 'absolute',
      top: -9999,
      overflow: 'scroll',
      MsOverflowStyle: 'scrollbar',
    });
    document.body.appendChild(div);
    scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
  } else {
    scrollbarWidth = 0;
  }

  return scrollbarWidth || 0;
};

export const getLastYears = (from, years) => {
  /*const lastYear = new Date().getFullYear();*/
  let result = [];
  [...new Array(years)].map((item, index) => {
    for (let i = 0; i < years; i++) {
      return result.push({ value: from - index });
    }
  });

  return result;
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import classnames from 'classnames';
import Slider from 'react-slick';

import LocalesPage from '../../LocalesPage';
import { useMobile } from '../../../../../hooks';

import Holder from '../blocks/Holder'
import { Block2 } from '../blocks/Block2'
import MathonLpHeader from '../blocks/MathonLpHeader';
import MathonLpFooter from '../blocks/MathonLpFooter';
import { TextBackTheme6} from '../../../../../themes/nerdify/components/TextBack';
import { TextBackWithLocales } from '../../../blocks/TextBack';
import MathonThankYouModal from '../MathonThankYouModal';

import './index.css';

import plusImg from './img/ic_add.svg';
import closeImg from './img/ic_close.svg';

const block3Items = [
  {
    number: '1',
    title: 'Sign up',
    text: 'Request a text-back and chat with our concierge service to get started.'
  },
  {
    number: '2',
    title: 'Tutor Match',
    text: 'Our concierge finds the perfect tutor tailored to your child\'s needs.'
  },
  {
    number: '3',
    title: 'Start Learning',
    text: 'Schedule the first tutoring session and watch your child\'s progress unfold.'
  },
  {
    number: '4',
    title: 'Stress-Free Tracking',
    text: 'Receive progress reports and keep track of your child\'s academic growth, stress-free.'
  },
];

const tutorsItems = [
  {
    avatar: '01',
    name: 'Ms. Sarah',
    quote: 'Believe in not just teaching math but making students fall in love with it.',
    tag: "Adaptive Teaching Star",
    quote2: "Known for her innovative teaching methods, Sarah brings a fresh and engaging approach to math.",
    education: "MIT_logo.svg"
  },
  {
    avatar: '02',
    name: 'Mr. John',
    quote: 'I don\'t just prep for tests, I unlock the real-world power of numbers.',
    tag: "Test-Prep Expert",
    quote2: "His teaching style integrates math with other disciplines, showcasing it as part of a larger context, not an isolated field.",
    education: "Boston_University_logo.svg"
  },
  {
    avatar: '03',
    name: 'Mrs. Lisa',
    quote: 'Discovering the beauty of math is the first step to understanding it.',
    tag: "Easy Explanation Master",
    quote2: "Utilises her comprehensive knowledge of math and her intuitive teaching style to simplify complex issues.",
    education: "penn_logo.svg"
  },
];

const feedbackItems = [
  {
    img: '01',
    message: 'The concierge service made the process smooth. MathOn found the perfect tutor for my son within hours, and I\'m constantly updated on his progress without having to ask.',
    name: 'Naomi R.',
    location: 'New York',
  },
  {
    img: '02',
    message: 'Got a great tutor for my high-schooler in no time. The progress reports are timely and keep us well-informed. Highly recommend!',
    name: 'Samuel M.',
    location: 'California',
  },
  {
    img: '03',
    message: 'Exceptional service! Within a day, my teen was paired with a math tutor who truly clicked with him. The regular updates keep me at ease and informed.',
    name: 'Lydia P.',
    location: 'Illinois',
  },
  {
    img: '04',
    message: 'I was initially skeptical, but the concierge service proved its worth. My daugher looks forward to math sessions, and I\'m always informed about her strides.',
    name: 'Jessica M.',
    location: 'Texas',
  },
];

const faq = [
  {
    title: 'How does online math tutoring work?',
    text: 'Students and tutors connect in real time through video conferencing and utilize a shared whiteboard for a dynamic learning experience. Tutors provide personalized instruction, address specific concerns, and guide students through practice problems to enhance their understanding of mathematical concepts.',
  },
  {
    title: 'What qualifications do your math tutors have?',
    text: 'Our math tutors are selected from the top 3% of applicants, ensuring they are highly qualified and experienced. Each tutor holds at least a bachelor\'s degree in mathematics or a related field and has substantial teaching or tutoring experience. We have a thorough onboarding process, which includes multiple rounds of interviews and teaching assessments, to guarantee that our tutors meet our high standards of excellence. This rigorous selection process is in place to ensure your child receives the best possible learning experience with us.',
  },
  {
    title: "Can you accommodate my child's specific learning needs?",
    text: 'Absolutely! At Math-On, we\'re all about adapting our tutoring to fit your child\'s unique learning style. Our expert tutors are great at customizing their teaching approaches to what works best for each student. If your child needs extra help in certain areas, a slower pace, or more challenging material, we\'ve got it covered.',
  },
];

const MathonFaqItem = ({ item }) => {
  const [isFaqOpen, setFaqOpen] = useState(false);

  const onClickToggleOpen = () => {
    setFaqOpen((isFaqOpen) => !isFaqOpen);
  };

  return (
    <div className="mathonlp__faq-item" onClick={onClickToggleOpen}>
      <div className="mathonlp__faq-item-title">
        <h3>{item.title}</h3>
        {isFaqOpen ?
          <img src={closeImg} width="40" height="40" alt="" />
          :
          <img src={plusImg} width="40" height="40" alt="" />
        }
      </div>
      {isFaqOpen && <div className="mathonlp__faq-item-text">{item.text}</div>}
    </div>
  );
};

const Mathon = (props) => {
  const isMobile = useMobile(1198);
  const { pageCfg } = props;

  const tutorItemList = tutorsItems.map((item, key) => (
    <div
      className="mathonlp__tutor-item"
      key={`mathon__tutor-${key}`}
    >
      <div className="mathonlp__tutor-avatar">
        <img
          src={require(`./img/tutor/${item.avatar}.png`)}
          srcSet={require(`./img/tutor/${item.avatar}@2x.png`) + ' 2x'}
          width="64"
          height="64"
          alt={`A profile picture of ${item.name}, one of the top tutors at MathOn`}/>
        <div className="mathonlp__tutor-name">{item.name}</div>
      </div>
      <div className="mathonlp__tutor-quote">
        <img src={require('./img/ic_quote.svg')} width="24" height="24" alt="" />
        <div className="mathonlp__tutor-quote-message">{item.quote}</div>
      </div>
      <div className="mathonlp__tutor-tag">
        <img src={require('./img/tutor/workspace.svg')} width="24" height="24" alt="" />
        <div className="mathonlp__tutor-tag-text">{item.tag}</div>
      </div>
      <div className="mathonlp__tutor-quote2">{item.quote2}</div>
      <img src={require(`./img/tutor/${item.education}`)} alt="" />
    </div>
  ));

  return (
    <LocalesPage {...props}>

      <MathonLpHeader />

      <div className="mathonlp">

        <div className="mathonlp__hero">
          <Holder type={1} className="mathonlp__hero-holder">
            <div className="mathonlp__hero-block">
              <h1 className="mathonlp__hero-title">
                { pageCfg.title || <>Learn <span className="mathonlp__highlight">Math Without Stress</span> with MathOn</> }
              </h1>
              <div className="mathonlp__hero-subtitle">
                Balancing work, parenting, and math tutors?
                MathOn is the easiest way to get a great math tutor your kid will love.
                Get started today!
              </div>
              <TextBackTheme6 {...props} subTheme={2}>
                <TextBackWithLocales {...props} submitBtnTxt={'Text me now'} />
              </TextBackTheme6>
              <div className="mathonlp__textback-label">
                💬 Our concierge will text you back in a minute to find best tutor for your specific goals.
              </div>
              <div className="mathonlp__textback-agree">
                By clicking the button you agree to our{' '}
                <Link className="link" to={'/terms-and-conditions'}>Terms</Link> and{' '}
                <Link className="link" to={'/privacy-policy'}>Privacy Policy</Link>.
              </div>
            </div>
            <img
              className="mathonlp__hero-img"
              src={require('./img/hero.png')}
              srcSet={require('./img/hero@2x.png') + ' 2x'}
              alt="Happy student and verified tutor from MathOn showcasing successful online math tutoring session"/>
          </Holder>
        </div>

        <Block2 block2ContentKey={pageCfg.block2Content} />

        <div className="mathonlp__block3">
          <Holder type={1} className="mathonlp__block3-holder">
            <h2 className="mathonlp__block3-title">
              How does Mathon <span className="highlight">Work?</span>
            </h2>
            <div className="mathonlp__block3-list">
              {block3Items.map((item, key) => (
                <div
                  className="mathonlp__block3-item"
                  key={`mathon__block3-item-${key}`}
                >
                  <div className="mathonlp__block3-item-number">{item.number}</div>
                  <h3 className="mathonlp__block3-item-title">{item.title}</h3>
                  <div className="mathonlp__block3-item-text">{item.text}</div>
                </div>
              ))}
            </div>
            <div className="mathonlp__block3-tip">
              <img
                className="mathonlp__block3-tip-img"
                src={require('./img/tutor_swipe.png')}
                srcSet={require('./img/tutor_swipe@2x.png') + ' 2x'}
                width="68"
                height="72"
                alt=""/>
                <div className="mathonlp__block3-tip-message">Prefer another tutor? Change any time until you find the best one your child loves.</div>
            </div>
          </Holder>
        </div>

        <div className="mathonlp__textback-block1">
          <Holder type={1} className="mathonlp__textback-block1-holder">
            <div className="mathonlp__textback-wrapper">

              <h2 className="mathonlp__textback-block1-title">
                Invest in your child's <span className="highlight">future</span> today
              </h2>
              <div className="mathonlp__textback-block1-subtitle">
                Multiple flexible packages available. Text us now to get instant pricing.
              </div>

              <TextBackTheme6 {...props} subTheme={2}>
                <TextBackWithLocales {...props} submitBtnTxt={'Text me now'} />
              </TextBackTheme6>

              <div className="mathonlp__textback-trust">
                <img
                  src={require('./img/ic_secure.svg')}
                  width="32" height="32"
                  alt=""/>
                <span className="mathonlp__textback-trust-message">Secure Subscription</span>
              </div>

              <img
                className="mathonlp__textback-block1-img1"
                src={require('./img/doodle3.svg')}
                width="116" height="119"
                alt=""/>
              <img
                className="mathonlp__textback-block1-img2 desktop-only"
                src={require('./img/doodle4.svg')}
                width="131" height="130"
                alt=""/>
            </div>
          </Holder>
        </div>


        <div className="mathonlp__tutor-block">
          <Holder type={1} className="mathonlp__tutor-holder">
            <h2 className="mathonlp__tutor-title">
              Meet our <span className="highlight">Top Tutors</span>
            </h2>
            <div className="mathonlp__tutor-subtitle">MathOn tutors are carefully selected by our multi-stage process. We only hire tutors with previous teaching experience and relevant educational backgrounds. All teachers go through an ID verification process.</div>
            <div className="mathonlp__tutor-list">

              {isMobile ? (
                <Slider
                  //variableWidth
                  //useTransform={false}
                  //centerMode
                  infinite={true}
                  slidesToShow={1}
                  slidesToScroll={1}
                  className={'main-slider'}
                  //adaptiveHeight={true}
                  swipe={true}
                  arrows={false}
                  dots={true}
                  customPaging={() => {
                    return (
                      <button className="slick-dots-btn" />
                    )
                  }}
                >
                  {tutorItemList}
                </Slider>
              ) : (
                <>{tutorItemList}</>
              )}

            </div>
          </Holder>
        </div>

        <div className="mathonlp__feedback-block">
          <Holder type={1} className="mathonlp__feedback-holder">
            <div className="mathonlp__feedback-title">
              <img
                className="mathonlp__feedback-title-img"
                src={require('./img/doodle2.svg')}
                alt=""/>
              <h2 className="mathonlp__feedback-title-message">
                Why do Parents <span className="highlight">Trust us</span>?
              </h2>
            </div>
            <div className="mathonlp__feedback-list">
              {feedbackItems.map((item, key) => (
                <div
                  className="mathonlp__feedback-item"
                  key={`mathon__feedback-${key}`}
                >
                  <img
                    className="mathonlp__block3-tip-img"
                    src={require(`./img/feedback/${item.img}.png`)}
                    srcSet={require(`./img/feedback/${item.img}@2x.png`) + ' 2x'}
                    width="64"
                    height="64"
                    alt={`A profile picture of ${item.name}, a parent who uses MathOn`}/>
                  <div className="mathonlp__feedback-line">
                    <img src={require('./img/ic_quote.svg')} width="24" height="24" alt="" />
                    <div className="mathonlp__feedback-info">
                      <div className="mathonlp__feedback-rating">5.0</div>
                      <img src={require('./img/feedback/ic_star.svg')} width="18" height="24" alt="" />
                      <img src={require('./img/feedback/ic_star.svg')} width="18" height="24" alt="" />
                      <img src={require('./img/feedback/ic_star.svg')} width="18" height="24" alt="" />
                      <img src={require('./img/feedback/ic_star.svg')} width="18" height="24" alt="" />
                      <img src={require('./img/feedback/ic_star.svg')} width="18" height="24" alt="" />
                    </div>
                  </div>
                  <div className="mathonlp__feedback-message">{item.message}</div>
                  <div className="mathonlp__feedback-name">{item.name}, {item.location}</div>
                </div>
              ))}
            </div>
          </Holder>
        </div>

        <div className="mathonlp__textback-block2">
          <Holder type={1} className="mathonlp__textback-block2-holder">
            <h2 className="mathonlp__textback-block2-title">
              Get Started <span className="highlight">Today!</span>
            </h2>
            <div className="mathonlp__textback-block2-subtitle">
              Invest in your child's future with our flexible subscription packages.<br /> Text us now to get instant pricing.
            </div>

            <TextBackTheme6 {...props} subTheme={2}>
              <TextBackWithLocales {...props} submitBtnTxt={'Text me now'} />
            </TextBackTheme6>

            <img
              className="mathonlp__textback-block2-img1 desktop-only"
              src={require('./img/doodle5.svg')}
              width="132" height="119"
              alt=""/>
            <img
              className="mathonlp__textback-block2-img2"
              src={require('./img/doodle6.svg')}
              width="75" height="86"
              alt=""/>
          </Holder>
        </div>

        <div className="mathonlp__faq">
          <Holder type={1} className="mathonlp__faq-holder">
            <h2 className="mathonlp__faq-title">Frequently Asked <span className="highlight">Questions</span></h2>
            <div className="mathonlp__faq-items">
              {faq.map((item, key) => (
                <MathonFaqItem key={`mathonlp__faq-item-${key}`} item={item} />
              ))}
            </div>
          </Holder>
        </div>

      </div>

      <MathonLpFooter />

      {pageCfg.thankYouModal &&
        <MathonThankYouModal {...props} />
      }
    </LocalesPage>
  );
};

export default Mathon;

import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { healthCheck } from "../../redux/actions";

import "./health-check.css";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const healthCheckHelper = useCallback(() => {
    dispatch(healthCheck())
      .then((resp) => {
        history.push("/");
        return resp;
      })
      .catch((error) => error);
  }, [dispatch, history]);

  useEffect(() => {
    document.querySelector("body").classList.add("fixed");

    healthCheckHelper();
  }, [healthCheckHelper]);

  const onClickClose = () => {
    healthCheckHelper();
  };

  return (
    <div className={"health-check-popup-layout"}>
      <div className="health-check-main">
        <div className="health-check-title"> Sorry! </div>
        <div className="health-check-subtitle">
          We are experiencing technical difficulties, but we’re already working
          on it.
        </div>
        <img
          className="health-check-img"
          src={require("./img/sorry.png")}
          srcSet={`${require("./img/sorry@2x.png")} 2x, ${require("./img/sorry@3x.png")} 3x`}
          alt=""
        />
        <div className="health-check-line">
          Please try again in a few minutes.
        </div>
        <button className="health-check-button" onClick={onClickClose}>
          Got it
        </button>
      </div>
    </div>
  );
};

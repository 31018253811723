import React from 'react';
//import { Link } from 'react-router-dom';

import MathonLpHeader from '../blocks/MathonLpHeader';
import MathonLpFooter from '../blocks/MathonLpFooter';

import '../content.css';

const MathonTerms = () => {
  return (
    <>
      <MathonLpHeader />
      <div className="mathon-content">
        <h1 className="mathon-h1">Terms and Conditions</h1>
{/*
        <div className="mathon-terms__date">Effective: September 1, 2022</div>
*/}
        <h2 className="mathon-h2">Acceptance of the Terms and Conditions</h2>
        <p className="mathon-p">By accessing or using this website, you agree to comply with and be bound by MathOn's Terms and Conditions and all applicable U.S. laws and regulations. If you do not agree to these terms, you are prohibited from accessing, reading, or otherwise using this website. These Terms remain effective from the time of acceptance and continue indefinitely, unless otherwise terminated according to its provisions.</p>

        <h2 className="mathon-h2">Description of Service</h2>
        <p className="mathon-p">MathOn is an online platform that facilitates math tutoring services. We connect parents to academic personal assistants who gather pertinent information about a child in need of tutoring. Based on the information collected, a suitable math tutor is provided. Additionally, the personal assistant manages scheduling, provides access to a student dashboard for the parents, and updates them on the child's progress.</p>

        <h2 className="mathon-h2">Service Provision Agreement</h2>
        <p className="mathon-p">MathOn serves as an intermediary connecting parents to qualified tutors. While we strive to connect students with the most suitable tutors, the final assessment and validation of the tutor's expertise, qualifications, and compatibility rests with the client.</p>

        <div className="mathon-h2">Registration and Account Security</div>
        <p className="mathon-p">Users of MathOn are required to provide accurate and up-to-date information. Misrepresentation or providing false information may lead to the termination of the account. Users are responsible for the security of their accounts, including safeguarding their passwords and other account details.</p>

        <h2 className="mathon-h2">Safety and Content Standards</h2>
        <p className="mathon-p">MathOn endeavors to maintain a safe and respectful platform. Users are prohibited from:</p>
        <ul className="mathon-ul">
          <li className="mathon-li">Posting unauthorized commercial communications.</li>
          <li className="mathon-li">Uploading viruses or other malicious code.</li>
          <li className="mathon-li">Bullying, intimidating, or harassing any user.</li>
          <li className="mathon-li">Posting content that promotes hate speech, violence, or discrimination.</li>
          <li className="mathon-li">Accessing another user's account without permission.</li>
          <li className="mathon-li">Violating any U.S. laws or regulations.</li>
        </ul>

        <h2 className="mathon-h2">Data Collection and Use</h2>
        <p className="mathon-p">We collect data such as names, emails, and phone numbers to facilitate our services. All data collection and use are governed by U.S. privacy laws and regulations.</p>

        <h2 className="mathon-h2">Links</h2>
        <p className="mathon-p">MathOn may contain links to external sites. We are not responsible for the content, accuracy, or practices of these sites. The inclusion of any link does not imply endorsement.</p>

        <h2 className="mathon-h2">Modifications to Terms</h2>
        <p className="mathon-p">MathOn reserves the right to modify these Terms and Conditions at any time. By continuing to use our services, you accept and agree to be bound by the updated terms.</p>

        <h2 className="mathon-h2">Governing Law</h2>
        <p className="mathon-p">These terms and conditions are governed by and construed in accordance with the laws of the United States. Any disputes arising out of or related to these terms will be subject to the jurisdiction of the courts of the United States.</p>

        <h2 className="mathon-h2">Contact</h2>
        <p className="mathon-p">For any questions or concerns regarding these Terms and Conditions, please contact MathOn's support team.</p>

{/*
        <Link to={'/privacy-policy'}>Privacy Policy</Link>
*/}
      </div>
      <MathonLpFooter />
    </>
  );
};

export default MathonTerms;

import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import Overlay from '../blocks/Overlay';
import { TextBackWithLocales } from '../../../blocks/TextBack'
import { TextBackTheme6} from '../../../../../themes/nerdify/components/TextBack';

// import LocalesPage from '../../LocalesPage';
// import classnames from 'classnames';

// import { updateUser, checkLeadExists } from '../../../../../redux/actions';

// import regexps from '../../../../../utils/regexps';
import {parseQuery, saveQueryToStorage} from '../../../../../utils';

import './index.css';

const MathonThankYouModal = (props) => {
  // const { pageCfg } = props;
  const history = useHistory();
  const location = useLocation();

  // const dispatch = useDispatch();
/*
    const { isPatching } = useSelector((state) => state.textback);

    const [email, setEmail] = useState('');
    //const [userId, setUserId ] = useState('')
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
  */

  const urlParams  = parseQuery(location.search);
  const phone  = props.location.state && props.location.state.phone || _.get(urlParams, 'to', '').split('-').join('') || '';
  // const landing_type  = props.location.state && props.location.state.landing_type || _.get(urlParams, 'landing_type', '') || '';

  let source_url = '';
  try {
    source_url = decodeURIComponent(props.location.state?.source_url ?? '');
  } catch (error) {
    console.error('Error decoding source_url from state:', error);
  }

  if (!source_url) {
    try {
      source_url = decodeURIComponent(_.get(urlParams, 'source_url', ''));
    } catch (error) {
      console.error('Error decoding source_url from URL params:', error);
    }
  }

  source_url = source_url || '';

  useEffect(() => {
    localStorage.removeItem('leadCreated');
    document.querySelector('body').classList.add('fixed');
  }, []);

/*
  useEffect(() => {
    if (history.length > 1) { // Проверяем, что есть предыдущие записи в истории
      const previousLocation = history.entries[history.index - 1];
      if (previousLocation && previousLocation.pathname === location.pathname) {
        history.go(-1);
      }
    }
  }, [history, location]);
*/

  const onCloseClick = () => {
    document.querySelector('body').classList.remove('fixed');

    if (source_url && source_url.includes('html') && typeof window !== 'undefined') {
      window.location.assign(source_url);
    }
    else if (source_url && phone) {
      try {
        history.push(new URL(source_url).pathname, {
          phone,
        })
      } catch(e) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  return (
      <Overlay>

        <div className="mathon-thank-modal">
          <button className="thank__close" onClick={onCloseClick}>
            <img src={require('./img/ic_close.svg')} width="24" height="24" alt="" />
          </button>
          <img src={require('./img/doodle_sms.svg')} width="104" height="104" alt="" />
          <div className="thank__title">
            We'll text you in a few<br className="mobile" /> seconds!
          </div>
            {/*
            {landing_type === 'second' || landing_type === 'undelivered' ?
              (
                <>Done! We’ve just texted you again!</>
              )
              : (
                <>We'll text you in a few<br className="mobile-only" /> seconds!</>
              )
            }
            */}
          <div className="thank__subtitle">
            If you don't receive our message, click the button below and<br className="desktop" /> we'll text you again.
          </div>

          <TextBackTheme6 {...props} subTheme={2}>
            <TextBackWithLocales {...props} submitBtnTxt={'Text me now'} />
          </TextBackTheme6>

        </div>
      </Overlay>
  );
};

export default MathonThankYouModal;

import React from 'react';

// import Mathon from './Mathon';
import MathonLp from './pages/Mathon/MathonLp';
import MathonPrivacyPolicy from './pages/Mathon/MathonPrivacyPolicy';
import MathonTerms from './pages/Mathon/MathonTerms';

// import ThankYou from './pages/ThankYou';
import NotFound from './pages/Mathon/NotFound';

// import { IS_PROD } from '../../config';
// import { lpdTitle } from '../../ldpTitles';

import './../../themes/nerdify/assets/css/index.css';

export const viewport = 'width=device-width';
// const dynamicHeadline = lpdTitle();

export const favicons = [
  {
    type: 'image/x-icon',
    sizes: false,
    href: '/favicon.ico',
  },
  {
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon-16x16.png',
  },
  {
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon-32x32.png',
  },
  {
    type: 'image/png',
    sizes: '96x96',
    href: '/favicon-96x96.png',
  },
  {
    type: 'image/png',
    sizes: '192x192',
    href: '/favicon-192x192.png',
  },
];

export const routes = [
  {
    path: '/',
    meta: {
      title: 'MathOn | Top Rated Online Math School',
      description:
        'MathOn: Seamless online math tutoring for K-12 students, with a dedicated 24/7 academic assistant for parents. Try it out now!',
      keywords:
        'Math-On, MathOn, Math On, Online Math School, K-12 Math Tutoring, Online Math Tutoring, Expert Math Tutors, One-on-One Math Tutoring, Personalized Math Education, Math Lessons, Math Tutoring Sessions',
      // robots: 'noindex, nofollow',
    },
    component: MathonLp,
    exact: true,
    pageCfg: {
      block2Content: 'lp1block2',
      thankYouPagePath: '/mathon/thankyou',
      landingGuid: {
        us: 'nerdifyit.com_mathon',
      },
    },
  },
  {
    path: '/mathon/lp1',
    meta: {
      title: 'MathOn | Online Math School',
      description:
        'MathOn is a one-of-a-kind online math school with personal tutors available through a convenient 24/7 concierge.',
      keywords:
        'Math tutors, math tutor, online math school, math school, online math tutor',
      // robots: 'noindex, nofollow',
    },
    component: MathonLp,
    exact: true,
    pageCfg: {
      block2Content: 'lp1block2',
      thankYouPagePath: '/mathon/thankyou',
      landingGuid: {
        us: 'nerdifyit.com_mathon',
      },
    },
  },
  { path: '/mathon/lp1.php', pageCfg: { redirectTo: '/mathon/lp1' }, },
  { path: '/mathon/lp1.html', pageCfg: { redirectTo: '/mathon/lp1' }, },
  { path: '/mathon/lp1.htm', pageCfg: { redirectTo: '/mathon/lp1' }, },

  {
    path: '/mathon/thankyou',
    meta: {
      title: 'MathOn | Online Math School',
      description:
        'MathOn is a one-of-a-kind online math school with personal tutors available through a convenient 24/7 concierge.',
      keywords:
        'Math tutors, math tutor, online math school, math school, online math tutor',
      // robots: 'noindex, nofollow',
    },
    component: MathonLp,
    exact: true,
    pageCfg: {
      block2Content: 'lp1block2',
      thankYouPagePath: '/mathon/thankyou',
      thankYouModal: true,
      landingGuid: {
        us: 'nerdifyit.com_mathon',
      },
    },
  },

  {
    path: '/privacy-policy',
    meta: {
      title: 'MathOn Privacy Policy',
      description: 'MathOn Privacy Policy',
      keywords: '',
      // robots: 'noindex, nofollow',
    },
    component: MathonPrivacyPolicy,
    exact: true,
    pageCfg: {},
  },
  { path: '/privacy-policy.php', pageCfg: { redirectTo: '/privacy-policy' }, },
  { path: '/privacy-policy.html', pageCfg: { redirectTo: '/privacy-policy' }, },
  { path: '/privacy-policy.htm', pageCfg: { redirectTo: '/privacy-policy' }, },

  {
    path: '/terms-and-conditions',
    meta: {
      title: 'MathOn Terms and Condition.',
      description: 'MathOn Terms and Condition.',
      keywords: '',
      // robots: 'noindex, nofollow',
    },
    component: MathonTerms,
    exact: true,
    pageCfg: {},
  },
  { path: '/terms-and-conditions.php', pageCfg: { redirectTo: '/terms-and-conditions' }, },
  { path: '/terms-and-conditions.html', pageCfg: { redirectTo: '/terms-and-conditions' }, },
  { path: '/terms-and-conditions.htm', pageCfg: { redirectTo: '/terms-and-conditions' }, },

/*
  {
    meta: {
      title: 'Nerdify | Error',
      description: 'Nerdify | Error page',
      keywords: '',
      // robots: 'noindex, nofollow',
    },
    component: NotFound,
    status: 404,
    pageCfg: {},
  },
*/
/*
  { pageCfg: { redirectTo: '/404.html' }, },
*/

];

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateUser } from './../../redux/actions';

import regexps from './../../utils/regexps';

export const TextBackModalHOC = (WrappedComponent) => {
  class TextBackModalHOC extends Component {
    constructor(props) {
      super(props);

      this.state = {
        timeIsPassed: true,
        isOpen: false,
      };

      this.setModalState = this.setModalState.bind(this);
      this.onClose = this.onClose.bind(this);
      this.keyUp = this.keyUp.bind(this);
    }

    componentDidMount() {
      document.addEventListener('keyup', this.keyUp);
    }

    keyUp(e) {
      const { isOpen } = this.state;

      if (isOpen && e.keyCode === 27) {
        this.onClose();
      }
    }

    setModalState(state) {
      this.setState(state);

      if (state.isOpen) {
        document.querySelector('body').classList.add('fixed');
      } else {
        document.querySelector('body').classList.remove('fixed');
      }
    }

    onClose() {
      this.setModalState({
        isOpen: false,
      });
    }

    render() {
      const { isOpen, timeIsPassed } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          isOpen={isOpen}
          timeIsPassed={timeIsPassed}
          setModalState={this.setModalState}
          onClose={this.onClose}
        />
      );
    }
  }

  TextBackModalHOC.displayName = `TextBackModalHOC(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return TextBackModalHOC;
};

export const TextBackWithEmailModalHOC = (WrappedComponent) => (
  ModalTextBackWithEmailSuccess,
  ModalTextBackWithEmailRepeat
) => {
  class TextBackWithEmailModalHOC extends Component {
    constructor(props) {
      super(props);

      this.state = {
        userId: undefined,
        email: '',
        errors: {},
        leadCreated: false,
      };

      this.onClose = this.onClose.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.setEmailModalState = this.setEmailModalState.bind(this);
      this.onChange = this.onChange.bind(this);
    }

    clearState() {
      this.setState({
        email: '',
        errors: {},
      });
    }

    onSubmit() {
      const { userId, email } = this.state;

      const isError = this.validate();

      if (isError) {
        return false;
      }

      this.clearState();

      this.props.updateUser(userId, email);

      localStorage.removeItem('leadCreated');

      this.props.onClose();
    }

    validate() {
      const { email } = this.state;

      const errors = {
        email: !regexps.email(email.trim()) ? 'Required field' : null,
      };

      const isError = Object.keys(errors).some((item) => errors[item]);

      this.setState({
        errors,
      });

      return isError;
    }

    onClose() {
      this.clearState();
      this.props.onClose();
    }

    setEmailModalState(state) {
      this.setState(state);
    }

    onChange(e) {
      const { name, value } = e.target;

      this.setState({
        [name]: value,
      });
    }

    render() {
      const { leadCreated, email, errors } = this.state;
      const { isOpen, timeIsPassed, isPatching } = this.props;

      const modalProps = {
        onSubmit: this.onSubmit,
        onClose: this.onClose,
        onChange: this.onChange,
        leadCreated,
        email,
        isPatching,
        errors,
      };

      return (
        <div className="textback__wrapper">
          <WrappedComponent
            {...this.props}
            setEmailModalState={this.setEmailModalState}
          />
          <ModalTextBackWithEmailSuccess
            {...modalProps}
            isOpen={timeIsPassed && isOpen}
          />
          <ModalTextBackWithEmailRepeat
            {...modalProps}
            isOpen={!timeIsPassed && isOpen}
          />
        </div>
      );
    }
  }

  TextBackWithEmailModalHOC.displayName = `TextBackWithEmailModalHOC(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return connect(
    (state, ownProps) => {
      const textback = state.textback;
      const { isPatching } = textback;

      return {
        isPatching,
      };
    },
    (dispatch, ownProps) => {
      return {
        updateUser: (id, email) => dispatch(updateUser(id, email)),
      };
    }
  )(TextBackWithEmailModalHOC);
};

export const TextBackWithRefModalHOC = (WrappedComponent) => (
  ModalTextBackWithRefSuccess,
  ModalTextBackWithRefRepeat
) => {
  class TextBackWithRefModalHOC extends Component {
    constructor(props) {
      super(props);

      this.state = {
        refLink: '',
      };

      this.createRefLink = this.createRefLink.bind(this);
      this.onClose = this.onClose.bind(this);
    }

    setRefModalState(refKey) {
      this.setState({
        refLink: `https://www.facebook.com/dialog/send?link=https://gonerdify.com/r/${refKey}&app_id=1482143762085653&redirect_uri=${window.location.origin}`,
      });
    }

    onClose() {
      this.props.onClose();
    }

    render() {
      const { refLink } = this.state;
      const { isOpen, timeIsPassed } = this.props;

      return (
        <div className="textback__wrapper">
          <WrappedComponent
            {...this.props}
            setRefModalState={this.setRefModalState}
          />
          <ModalTextBackWithRefSuccess
            onClose={this.onClose}
            isOpen={timeIsPassed && isOpen}
            refLink={refLink}
          />
          <ModalTextBackWithRefRepeat
            onClose={this.onClose}
            isOpen={!timeIsPassed && isOpen}
            refLink={refLink}
          />
        </div>
      );
    }
  }

  TextBackWithRefModalHOC.displayName = `TextBackWithRefModalHOC(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return TextBackWithRefModalHOC;
};

import React, { Component } from 'react';
import { setCookie, checkCookie } from './../../utils';
import { getUserIpInfo } from './../../api';
import { NavLink } from 'react-router-dom';
import './cookie-popup.css';

import { REACT_APP_SITENAME } from './../../config';

class CookiePopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCookieSet: false,
      isValidCountry: false,
    };

    this.handleOnClickSetCookie = this.handleOnClickSetCookie.bind(this);
  }

  componentDidMount() {
    const isCookieSet = checkCookie('__cookieEnabled');

    if (!isCookieSet) {
      getUserIpInfo()
        .then((resp) => {
          const code = resp?.country_code;

          if (!code) {
            return false;
          }

          this.setState({
            isValidCountry: ['us', 'ca'].indexOf(code.toLowerCase()) === -1,
          });
        })
        .catch((error) => error);
    }

    this.setState({
      isCookieSet: isCookieSet,
    });
  }

  handleOnClickSetCookie() {
    console.log('set cookie');
    this.setState(
      {
        isCookieSet: true,
      },
      () => {
        setCookie('__cookieEnabled', 1, 365);
      }
    );
  }

  render() {
    const { isFetching } = this.props;

    if (isFetching) {
      return null;
    }

    const { isValidCountry, isCookieSet } = this.state;
    const hasCookiePopUp = isValidCountry && !isCookieSet;
    //console.log(1, isValidCountry)

    return (
      hasCookiePopUp && (
        <div className="cc-window cc-banner cc-type-info cc-theme-block cc-bottom cc-color-override-349720510">
          <span className="cc-message">
            {REACT_APP_SITENAME} uses cookies to ensure you get the best
            experience on our website.{' '}
            <NavLink className="cc-link" to="/privacy-policy">
              Learn more
            </NavLink>
          </span>
          <div className="cc-compliance">
            <button
              className="cc-btn cc-dismiss"
              onClick={this.handleOnClickSetCookie}
            >
              Got it!
            </button>
          </div>
        </div>
      )
    );
  }
}

export default CookiePopUp;

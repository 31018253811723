import React from 'react';
import classnames from 'classnames';

import textBackIcons from './img/icons.svg';

import {
  TextBack,
  TextBackForm as TextBackFormBase,
} from '../../../../base_components/TextBack';

import {
  TextBackModalHOC,
  TextBackWithEmailModalHOC,
} from '../../../../base_components/TextBack/TextBackModal';

import './index.css';

import {
  ModalTextBackWithEmailSuccess,
  ModalTextBackWithEmailRepeat,
} from './../Modal';

export const TextBackTheme1 = (props) => (
  <TextBack {...props} className={'textback_1'} />
);
export const TextBackTheme2 = (props) => (
  <TextBack {...props} className={'textback_2'} />
);
export const TextBackTheme3 = (props) => (
  <TextBack
    {...props}
    className={classnames('textback_3', {
      [`textback_3_${props.subTheme}`]: props.subTheme,
    })}
  />
);
export const TextBackTheme4 = (props) => (
  <TextBack
    {...props}
    className={classnames('textback_4', {
      [`textback_4_${props.subTheme}`]: props.subTheme,
    })}
  />
);
export const TextBackTheme6 = (props) => (
  <TextBack
    {...props}
    className={classnames('textback_6', {
      [`textback_6_${props.subTheme}`]: props.subTheme,
    })}
  />
);

export const TextBackForm = TextBackModalHOC(
  TextBackWithEmailModalHOC((props) => {
    return <TextBackFormBase {...props} textBackIcons={textBackIcons} />;
  })(ModalTextBackWithEmailSuccess, ModalTextBackWithEmailRepeat)
);

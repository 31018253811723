import React from 'react';
import classnames from 'classnames';

import { Ripple } from './index.js';

class RippleButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cursorPos: {},
      pushState: false,
    }

    this.handleClick = this.handleClick.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onClick() {
    const { onClick } = this.props;

    clearTimeout(this.timeout);
    this.setState({ pushState: true });
    this.timeout = setTimeout(() => this.setState({ pushState: false }), 100);

    if (!onClick) {
      return;
    }
    onClick();
  }

  handleClick(e) {
    // Get Cursor Position
    let cursorPos = {
      top: e.clientY,
      left: e.clientX,
      time: Date.now()
    }
    this.setState({ cursorPos: cursorPos })
  }

  render() {
    const { className, children, onClick, ...restProps } = this.props;
    const { pushState } = this.state;

    const btnClassName = classnames('Ripple-parent', { pushState }, className);
    return (
      <button
        className={btnClassName}
        onMouseUp={this.handleClick}
        onTouchEnd={this.handleClick}
        onClick={this.onClick}
        {...restProps}
      >
        {children}
        <Ripple cursorPos={this.state.cursorPos} />
      </button>
    )
  }
}

export default RippleButton;
